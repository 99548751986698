<template>
  <div id="Safety" v-if="safetySingleton.show_section">
    <v-container class="mt-6 mt-sm-3 mt-xl-8" :fluid="$vuetify.breakpoint.width > 2299">
      <v-row justify="center">
        <v-col cols="11" sm="10" md="11" lg="8" class="px-7 px-sm-3">
          <v-img
            v-if="safetySingleton.cover !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              safetySingleton.cover.path
            "
            contain
            eager
            class="img-safety"
          ></v-img>

          <div class="div-texts-safety">
            <div class="div-text-165">
              <span
                class="
                  d-block
                  markazi
                  semibold
                  text-sm-center
                  titles-app-new-safety
                "
                v-html="safetySingleton.title"
              >
              </span>
            </div>

            <p
              v-html="safetySingleton.text1"
              style="white-space: pre-line"
              class="text-continuos"
            ></p>

            <span
              class="
                d-block
                markazi
                semibold
                text-sm-center
                titles-xxl
                text-testimonial-161
              "
              >"{{ safetySingleton.testimonial.text }}”</span
            >

            <span class="d-block pinyon semibold text-sm-center mt-8 titles-xxl"
              >— {{ safetySingleton.testimonial.autor }}</span
            >
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-if="render" class="mt-xl-12 mb-12">
      <v-row class="my-12">
        <v-col class="pa-0" id="carouselSafety">
          <carousel
            :dots="false"
            :nav="false"
            :loop="true"
            :center="true"
            :responsive="{
              0: { margin: 16, items: 1.5 },
              600: { margin: 50, items: 1.8 },
            }"
            :autoplay="true"
            :autoplayTimeout="3000"
            height="400"
          >
            <template slot="prev"
              ><span class="prev white--text"
                ><v-icon class="white--text">mdi-chevron-left</v-icon></span
              ></template
            >
            <div v-for="(image, i) in safetySingleton.carousel" v-bind:key="i">
              <template v-if="image !== null">
                <img
                  v-bind:src="
                    'https://www.lakewaycms.actstudio.xyz' + image.path
                  "
                  class="img-carousel"
                />
              </template>
            </div>
            <template slot="next"
              ><span class="next"
                ><v-icon class="white--text">mdi-chevron-right</v-icon></span
              ></template
            >
          </carousel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Safety",
  components: { carousel },
  data() {
    return {
      render: false,
      safetySingleton: {
        cover: {
          path: "",
        },
        testimonial: {
          text: "",
          autor: "",
        },
      },
    };
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }
    this.safetySingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/safety?q=" + key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.safetySingleton = data.content));

    this.render = true;
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

$markazi-regular: "MarkaziText-Regular";
$inter-regular: "Inter", sans-serif;
$inter-bold: "Inter-SemiBold";
$pinyon: "Pinyon Script", cursive;
$roboto: "Roboto", sans-serif;

.img-safety {
  @media screen and (min-width: $xxl) {
    height: 800px;
    width: 1560px;
    margin: auto;
  }
  @media screen and (max-width: $xxl) {
    height: 600px;
  }

  @media screen and (max-width: $xl) {
    height: 438px;
  }

  @media screen and (max-width: $sm) {
    height: auto;
  }
}

.div-texts-safety {
  margin: auto;
  margin-top: 55px;
  max-width: 1449px;

  @media screen and (max-width: $xxl) {
    max-width: 1049px;
  }

  @media screen and (max-width: $xl) {
    margin-top: 38px;
  }

  @media screen and (max-width: $lg) {
    max-width: 777px;
    margin-top: 48px;
  }
}

.text-continuos {
  margin-top: 130px;
  margin-bottom: 150px;

  @media screen and (max-width: $xxl) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media screen and (max-width: $xl) {
    margin-bottom: 50px;
    margin-top: 59px;
  }

  @media screen and (max-width: $lg) {
    margin-top: 32px;
  }
}

.img-carousel {
  height: inherit;
  object-fit: cover;
}
.div-text-165 {
  margin-top: 100px;
  @media screen and (max-width: $xl) {
    margin-top: 59px;
  }
}
.text-testimonial-161 {
  @media screen and (max-width: $md) {
    max-width: 500px;
    margin: auto;
  }
}
</style>